<template>
    <div class="details-content">
        <div class="details-container trading-record">
            <!-- 账户 -->
            <div class="bank clearfix c99 ">
                <div class="b-l fl">
                    账户余额(元)：<span class="c33 cf5 font-s30 bold">{{doctorAccountData.amount}}</span>
                    <el-tooltip class="item" effect="dark" v-if="doctorAccountData.freez_price>0" :content="doctorAccountData.freez_price+'元账期未到，暂不可提现'" placement="top-start">
                       <el-button><i class="el-icon-warning"></i></el-button>
                    </el-tooltip>
                </div>
                <div class="b-r fl">
                    <div v-if="doctorAccountData.card_number!='' && doctorAccountData.card_number!= undefined ">
                        <div>安全卡账号：</div>
                        <span class="bold c33 mr-20">{{doctorAccountData.card_number}}</span>
                        <el-button type="text"  @click="delBack(doctorAccountData.card_id)">删除</el-button>
                    </div>
                </div>
            </div>
            <!-- 按钮 -->
            <div class="mt-20">
                  <el-row>
                    <el-col :span="5" class="text-left">
                        <el-button @click="exportData">导出</el-button>
                    </el-col>
                    <el-col :span="19" class="text-right">
                        <el-input  placeholder="请输入关键字查询" v-model="search.content" style="width: 160px;" class="mr-20"></el-input>
                        <el-button type="success" plain  @click="serach">搜索</el-button>
                    </el-col>
                </el-row>
            </div>
            <!-- 表格 -->
            <div class="mt-20">
                <el-table ref="multipleTable"  :data="list" tooltip-effect="dark"  style="width: 100%"
                    @selection-change="selectionChange" :empty-text="tableTip">
                    <el-table-column type="selection" width="60"></el-table-column>
                    <el-table-column prop="order_no" label="交易单号" min-width="160"></el-table-column>
                    <el-table-column prop="order_type" label="订单类型" min-width="100"></el-table-column>
                    <el-table-column prop="username" label="用户名" min-width="100"></el-table-column>
                    <el-table-column prop="type_price" label="金额(元)" min-width="100"></el-table-column>
                    <el-table-column prop="addtime" label="交易时间" min-width="200"></el-table-column>
                    <el-table-column prop="to_time" label="到账时间" min-width="100"></el-table-column>
                    <el-table-column prop="trader_status" label="交易状态" min-width="100"></el-table-column>
                    <el-table-column prop="refund_message" label="备注" min-width="100"></el-table-column>
                </el-table>
            </div>
            <!-- 分页 -->
            <div class="communal">
                <div class=" paging">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        @size-change="handleSizeChange"
                        :current-page="currentPage"
                        :page-sizes="[10, 50, 100, 2000]"
                        :page-size="psize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totals"
                    ></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DoctorAccount } from "../../api/api"; //医生账户接口
import { DeleteAccount } from "../../api/api"; //删除医生账户接口
import { DoctorTraderList } from "../../api/api"; //交易记录接口
import { ExportDoctorTraders } from "../../api/api"; //医生交易数据导出接口
export default {
  data() {
    return {
      did: this.$route.query.doctor_info_id,
      from: this.$route.query.from,
      totals: 0,
      pages: "1",
      psize: 10,
      currentPage: 1, //默认显示页
      list: [],
      tableTip: "系统正在检索中…",
      exportDataSel: [],//选择导出数据
      doctorAccountData:'',//医生账户详情
      search: {
        content: "" //文本内容
      },
    };
  },
  created() {
    if(this.did!=undefined){
        this.getDoctorAccount(); //医生账户详情
        this.getList();//表格
    }else{
        this.tableTip = "暂无搜索结果";
    }
  },
  methods: {
    getDoctorAccount(){//医生账户详情
      var db={
        doctor_info_id:this.did
      }
      this.$axios.post(DoctorAccount, db).then(
        res => {
          if (res.data.code == 1) {
           this.doctorAccountData=res.data.data
          } 
        },
        err => {}
      );
    },
    delBack(card_id){//删除医生id
        var db={
            card_id:card_id
        }
        this.$axios.post(DeleteAccount, db).then(
            res => {
                if (res.data.code == 1) {
                    this.$message({
                        message: res.data.msg,
                        type: 'success'
                    });
                    this.getDoctorAccount(); //医生账户详情
                } else{
                    this.$message.error(res.data.msg);
                }
            },
            err => {}
        );
    },
    handleCurrentChange(val) {
      this.pages = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.psize = val;
      this.getList();
    },
    serach() {
      //搜索
      this.getList("搜索");
    },
    getList(operationName){
      var that = this;
      var db = {
        doctor_info_id: this.did, //	医生信息ID
        pages: this.pages,
        pnums: this.psize,
        txt: this.search.content //搜索内容
      };
      this.$axios.post(DoctorTraderList, db).then(
        res => {
          if (res.data.code == 1) {
            if (res.data.data != undefined && res.data.data.length > 0) {
              this.list = res.data.data;
              this.totals = res.data.count;
            } else {
              this.list = [];
              this.totals = res.data.count;
              if (operationName == undefined) {
                this.tableTip = "暂无交易记录";
              } else {
                this.tableTip = "暂无搜索结果";
              }
            }
          } else {
            this.list = [];
            this.tableTip = "暂无交易记录";
          }
        },
        err => {
          this.list = [];
          this.tableTip = "暂无交易记录";
        }
      );
    },
    selectionChange(sel) {
      //表格选中获取当前选中行
      this.exportDataSel = sel;
    },
    exportData() {
      //导出
      if (this.exportDataSel.length <= 0) {
        this.$message.info("至少选择一条交易进行导出");
      } else {
        var ids = [];
        for (var i = 0; i < this.exportDataSel.length; i++) {
          ids.push(this.exportDataSel[i].log_id);
        }
        var db = {
          doctor_info_id:this.did,
          log_ids: ids.join(",")
        };
        this.$axios
          .post(ExportDoctorTraders, db, { responseType: "arraybuffer" })
          .then(
            res => {
              let blob = new Blob([res.data], {
                type: "application/vnd.ms-excel"
              });
              const fileName = res.headers["content-disposition"].match(
                /filename=(\S*).xls/
              )[1];
              const elink = document.createElement("a");
              elink.download = JSON.parse(fileName) + ".xls";
              elink.href = window.URL.createObjectURL(blob);
              elink.click();
              window.URL.revokeObjectURL(elink.href);
            },
            err => {}
          );
      }
    },
  }
};
</script>

<style lang="scss" scope>
.trading-record {
  .bank {
    padding: 30px 28px;
    border: 1px #eeeeee solid;
    .b-l {
      width: 50%;

      .el-button {
        border: none;
      }
      .el-button:hover,
      .el-button:focus {
        color: #606266;
        background-color: transparent;
      }
    }
    .b-r {
      width: 50%;
    }
  }
}
</style>
