<template>
    <div id="viewInfo">
        <el-button class="back" size="small" plain @click="goBack">返回 <i class="el-icon-arrow-right"></i></el-button>
        <el-tabs v-model="activeName">
            <el-tab-pane label="基本信息" name="first" lazy>
                <doctorAddEdit ref="doctorAddEdit" @sureGoback="sureGoback"></doctorAddEdit>
            </el-tab-pane>
            <!-- <el-tab-pane label="咨询模板" name="two" lazy>
                <advisoryReplyList ref="advisoryReplyList" @sureGoback="sureGoback"></advisoryReplyList>
            </el-tab-pane> -->
            <el-tab-pane label="交易记录" name="third" lazy>
                <tradingRecord ref="tradingRecord" @sureGoback="sureGoback"></tradingRecord>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import doctorAddEdit from "./doctorAddEdit";
import { constants } from 'crypto';
import tradingRecord from "./tradingRecord";

// import advisoryReplyList from "./advisoryReplyList";
export default {
    components:{
        doctorAddEdit,
        tradingRecord,
        // advisoryReplyList
    },
    data() {
        return {
            activeName:"first",
            froms:'',
        };
    },
    mounted() {
    },
    methods:{
        goBack(){
            this.froms =  this.$refs.doctorAddEdit.from;
            if(this.activeName == 'first'){
                var isSave = this.$refs.doctorAddEdit.isSave;
                var uid = this.$refs.doctorAddEdit.uid;
                var did = this.$refs.doctorAddEdit.did;
                var status = this.$refs.doctorAddEdit.statue;
                if(uid != undefined && did != undefined){
                    if(this.froms == 'doctorManageD'){
                        this.goPreBack();
                    }else{
                        if(!isSave){
                            this.$refs.doctorAddEdit.cancelExit();
                        }else{
                            this.goPreBack();
                        }
                    }
                }else{
                    this.goPreBack();
                }
            }else{
                this.goPreBack();
            }
        },
        sureGoback(val){
            this.goPreBack();
        },
        goPreBack(){
            if(this.froms==undefined){
                this.$router.push({
                    path:'/doctorManage',
                });
            }else{
                this.$router.push({
                    path:this.froms,
                });
            }
        },
    },
    
}
</script>
<style lang="scss">
.el-tabs__nav-scroll{
    padding-left: 30px;
    padding-right: 30px;
}
.el-tabs__item{
    height: 60px;
    line-height: 60px;
}
.el-tabs__nav-wrap::after{
    background-color:#f4f4f4;
}
.el-tabs__header{
    margin-bottom: 0px;
}
#viewInfo {
  position: relative;
}
.back {
  position: absolute;
  right: 20px;
  top: 15px;
  z-index: 100;
}
</style>


